<template>
  <div class="statistics-scrollbar">
    <custom-scrollbar
      :ops="ops"
      class="m-auto"
    >
      <div class="p-4">
        <b-container fluid="xl">
          <b-row class="d-flex align-items-center">
            <b-col
              cols="2"
              class="d-flex align-items-center"
            >
              <span
                class="page-title"
                style="margin-right: 20px"
              >
                График
              </span>
            </b-col>
            <b-col cols="1">
              <span class="title-unloading">
                Период
              </span>
            </b-col>
            <b-col
              class="d-flex align-items-center"
              cols="5"
            >
              <div class="statistics-chart__field">
                <b-input
                  v-model="$v.statisticsFrom.$model"
                  type="date"
                  :max="maxStatisticsDateFrom"
                />

                <div
                  v-if="$v.statisticsFrom.$error"
                  class="text-danger small statistics-chart__field-error"
                >
                  {{ getValidationErrors('statisticsFrom')[0] }}
                </div>
              </div>

              <div class="divider">
                <div class="divider-row" />
              </div>

              <div class="statistics-chart__field">
                <b-input
                  v-model="$v.statisticsTo.$model"
                  type="date"
                  :min="minStatisticsDateTo"
                  :max="maxDateTo"
                />

                <div
                  v-if="$v.statisticsTo.$error"
                  class="text-danger small statistics-chart__field-error"
                >
                  {{ getValidationErrors('statisticsTo')[0] }}
                </div>
              </div>
            </b-col>
            <b-col
              cols="4"
              class="d-flex align-items-center"
            >
              <div>
                <span class="statistics-radiobuttons--title">
                  Единица:
                </span>
              </div>
              <div
                class="d-flex flex-column"
                style="padding-top: 10px;"
              >
                <base-radio-button
                  v-model="statisticsPeriod"
                  :name="3"
                  class="crm-radiobutton"
                >
                  Месяц
                </base-radio-button>

                <base-radio-button
                  v-model="statisticsPeriod"
                  :name="2"
                  class="crm-radiobutton"
                >
                  Неделя
                </base-radio-button>

                <base-radio-button
                  v-model="statisticsPeriod"
                  :name="1"
                  class="crm-radiobutton"
                >
                  День
                </base-radio-button>
              </div>
              <div class="d-flex flex-wrap justify-content-end">
                <b-button
                  :type="$const.SECONDARY_BUTTON"
                  variant="outline-primary"
                  size="sm"
                  @click="resetStatisticsFilters"
                >
                  Сбросить
                </b-button>

                <b-button
                  :type="$const.SECONDARY_BUTTON"
                  variant="primary"
                  class="mt-2"
                  size="sm"
                  :disabled="isLoading || $v.statisticsFrom.$error || $v.statisticsTo.$error"
                  @click="fetchStatistics"
                >
                  Применить
                </b-button>
              </div>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center mt-4 justify-content-between">
            <div class="d-flex align-items-center" />

            <div class="d-flex flex-column" />
          </div>

          <div class="justify-content-center mt-4">
            <statistics-consultations />
            <statistics-process-time />
          </div>
        </b-container>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { subMonths } from 'date-fns';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { BaseRadioButton } from '@/components/base';

import StatisticsConsultations from '@/components/Statistics/StatisticsConsultations';
import StatisticsProcessTime from '@/components/Statistics/StatisticsProcessTime';

// import ExelIcon from 'assets/images/exel_icon.svg';

export default {
  name: 'Statistics',
  page: {
    title: 'CRM Doctis - Статистика',
  },
  components: {
    BaseRadioButton,
    StatisticsConsultations,
    StatisticsProcessTime,
    // ExelIcon,
  },
  mixins: [validationMixin],
  data() {
    return {
      unloadingFrom: this.dateForInput(subMonths(new Date(), 1)),
      unloadingTo: this.dateForInput(new Date()),
      statisticsFrom: this.dateForInput(subMonths(new Date(), 1)),
      statisticsTo: this.dateForInput(new Date()),
      statisticsPeriod: 2,
      unloadingKtgFrom: this.dateForInput(subMonths(new Date(), 1)),
      unloadingKtgTo: this.dateForInput(new Date()),
      ops: {
        scrollPanel: {
          scrollingX: true,
        },
      },
      isLoading: false,
    };
  },
  validations: {
    unloadingFrom: {
      required,
    },
    unloadingTo: {
      required,
    },
    statisticsFrom: {
      required,
    },
    statisticsTo: {
      required,
      notBeforeFrom(value) {
        if (!value) return true;
        return new Date(value) >= new Date(this.statisticsFrom);
      },
      notInFuture(value) {
        if (!value) return true;
        return new Date(value) <= new Date();
      },
    },
    unloadingKtgFrom: {
      required,
    },
    unloadingKtgTo: {
      required,
    },
    unloadingGroup: ['unloadingFrom', 'unloadingTo'],
    ktgGroup: ['unloadingKtgFrom', 'unloadingKtgTo'],
  },
  watch: {
    statisticsFrom(newVal) {
      const today = new Date().toISOString().split('T')[0];
      if (newVal && new Date(newVal) > new Date(today)) {
        this.statisticsFrom = today; // Если выбрали дату в будущем, сбрасываем на сегодня
      }
    },
  },
  computed: {
    maxDateTo() {
      return this.dateForInput(new Date());
    },
    maxStatisticsDateFrom() {
      return this.statisticsTo || this.dateForInput(new Date());
    },
    minStatisticsDateTo() {
      return this.statisticsFrom || null;
    },
  },
  created() {
    this.fetchStatistics();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.STATISTICS_CLEAR);
  },
  methods: {
    getValidationErrors(field) {
      const errors = [];

      if (!this.$v[field].required) {
        errors.push('Поле не может быть пустым');
      }

      if (!this.$v[field].isValid) {
        errors.push('Неверное значение поля');
      }

      return errors;
    },
    async fetchStatistics() {
      if (this.$v.statisticsFrom.$error || this.$v.statisticsTo.$error) return;

      this.isLoading = true;

      try {
        await this.$store.dispatch(this.$types.STATISTICS_FETCH, {
          to: this.statisticsTo,
          from: this.statisticsFrom,
          dateRangeKind: this.statisticsPeriod,
        });
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    resetStatisticsFilters() {
      this.statisticsFrom = this.dateForInput(new Date());
      this.statisticsTo = this.dateForInput(new Date());
      this.statisticsPeriod = 2;
    },
    dateForInput(date) {
      return new Date(date).toISOString().split('T')[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-scrollbar {
  height: 100vh;
  margin: 0 auto;
}
.statistics {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 800px;
  &-chart__field {
    position: relative;
    min-width: 150px;
    &-error {
      margin-top: 3px;
      position: absolute;
      white-space: nowrap;
    }
  }
}
.crm-container {
  &.crm-container--title {
    margin-bottom: 50px;
    justify-content: space-between;
  }

  &.crm-container--title,
  .crm-container--unloading {
    justify-content: space-between;
  }

  &.crm-container-ktg {
    margin-top: 40px;
  }
}

.unloading-menu {
  display: flex;
  flex-basis: 870px;
  align-items: center;
  justify-content: space-between;
}
.chartWrapper {
  display: flex;
  width: 90%;
  flex-flow: column;
}
.unloading-datepickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 605px;
}
.statistics-datepickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statistics-radiobuttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statistics-radiobuttons--title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
}
.statistics-radiobuttons {
  margin-left: 20px;
}
.crm-radiobutton {
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.title-unloading {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $black-light;
  margin-right: 20px;
}
.crm-datepicker {
  flex-basis: 168px;
}
.crm-buttons-wrapper {
  display: flex;
  width: 269px;
}
.button_transparent {
  background-color: transparent;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 0;
  flex-basis: 127px;

  &.without-border {
    border: none;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.unloading-button {
    flex-basis: 168px;
    //margin-left: 220px;
  }

  .exel-icon{
    margin-left: 6px;
  }
}
.crm-container--unloading {
  margin-bottom: 50px;
}
</style>
